.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  width: max-content;
  text-align: center;
  font-size: 20px;
}

.stretched {
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .container:hover {
    opacity: 0.8;
  }
}

.container:active {
  opacity: 0.6;
}

.after {
  display: flex;
  margin: auto;
}

.before {
  display: flex;
  margin: auto;
}

.primary {
  background: var(--background_button_primary);
  color: var(--text_button_primary);
}

.white {
  border: 3px solid var(--background_button_white);
  color: var(--text_button_white);
}

.disabled {
  opacity: 0.6;
  cursor: auto;
}

@media (hover: hover) and (pointer: fine) {
  .disabled:hover {
    opacity: 0.8;
  }
}

.disabled:active {
  opacity: 0.6;
}

@media screen and (max-width: 600px) {
  .container {
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    font-size: 16px;
  }
}
