.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.title {
  font-size: 30px;
  color: var(--text_primary);
}

.title_accent {
  font-size: 30px;
  font-weight: 700;
  color: var(--text_primary);
}

.content_wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.img_wrapper {
  border-radius: 16px;
  height: max-content;
}

.text {
  font-size: 18px;
  line-height: 200%;
  color: var(--text_primary);
  text-align: justify;
}

.admin_textarea {
  width: 100%;
  height: 100%;
  color: var(--text_primary);
  border: 1px solid var(--border_input);
}

.admin_img_wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.upload_file {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

@media screen and (max-width: 850px) {
  .content_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .img_wrapper {
    max-width: 350px;
  }
}
