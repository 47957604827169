.wrapper {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  gap: 50px;
}

.button_wrapper {
  position: fixed;
  top: 12px;
  left: 12px;
  z-index: 5;
}

.nav_wrapper {
  display: flex;
  gap: 24px;
}

.nav_item {
  font-size: 20px;
  font-weight: 600;
  color: var(--text_nav);
}

@media (hover: hover) and (pointer: fine) {
  .nav_item:hover {
    opacity: 0.8;
  }
}

.nav_item:active {
  opacity: 0.6;
}

@media screen and (max-width: 1030px) {
  .wrapper {
    padding: 0px 30px;
  }
}

@media screen and (max-width: 550px) {
  .wrapper {
    padding: 0px 16px;
  }
}
