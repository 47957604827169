body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background_page);
}

:root {
  overflow-x: hidden;

  /*Цвет фона*/
  --background_page: #3b3428;
  --background_block: #484137;
  --background_block_accent: #524a3d;

  /*Цвет текста*/
  --text_primary: #ffffff;
  --text_secondary: #eacf9f;
  --text_nav_active: #e8d8bb;
  --text_nav: #cbb999;
  --text_placeholder: #aea89d;

  /*Цвет иконок*/
  --icon_primary: #ffffff;

  /*Кнопки*/
  --background_button_primary: #cbb999;
  --text_button_primary: #ffffff;
  --background_button_white: #ffffff;
  --text_button_white: #000000;

  /*Обводки*/
  --border_input: #e8d8bb;
  --border_img: #817159;
}

/* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus,
:active {
  /*outline: none;*/
}

a:focus,
a:active {
  /* outline: none;*/
}

/* Links */

a,
a:link,
a:visited {
  /* color: inherit; */
  text-decoration: none;
  /* display: inline-block; */
}

a:hover {
  /* color: inherit; */
  text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}
