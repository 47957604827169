.auth_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  padding: 50px;
}

.title {
  font-size: 30px;
  font-weight: 700;
  color: var(--text_primary);
}

.form_wrapper {
  min-width: 300px;
  max-width: 500px;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  height: auto;
  background: var(--background_block_accent);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.input_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--text_secondary);
}

.input_text_top {
  font-size: 14px;
  color: var(--text_secondary);
}

.input {
  border: 1px solid var(--border_input);
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;
  color: var(--text_secondary);
}

input::placeholder {
  color: var(--text_placeholder);
}
