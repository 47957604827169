.text {
  font-size: 20px;
  font-weight: 600;
  color: var(--text_nav);
}

@media (hover: hover) and (pointer: fine) {
  .text:hover {
    opacity: 0.8;
  }
}

.text:active {
  opacity: 0.6;
}

.text.active {
  position: relative;
  opacity: 1;
  //color: var(--text_nav_active);
  color: red;
}

.text.active::before {
  content: "";
  position: absolute;
  display: flex;
  top: 100%;
  height: 2px;
  width: 100%;
  border-radius: 100px;
}
