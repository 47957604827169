.wrapper {
  background: var(--background_block);
  display: flex;
  width: max-content;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 0px 0px 16px 16px;
  margin-left: auto;
}

.social_item {
  transition: 1s;
}

@media (hover: hover) and (pointer: fine) {
  .social_item:hover {
    transform: scale(1.5);
  }
}

@media screen and (max-width: 850px) {
  .wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}
