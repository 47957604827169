.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 50px;
}

.text {
  white-space: pre-line;
  font-size: 18px;
  color: var(--text_primary);
  text-align: justify;
}

.block_form {
  display: flex;
  padding: 16px;
  border-radius: 16px;
  flex-direction: column;
  gap: 24px;
  background: var(--background_block_accent);
}

.title {
  font-size: 30px;
  color: var(--text_primary);
}

.title_accent {
  font-size: 30px;
  font-weight: 700;
  color: var(--text_primary);
}

.input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.input_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--text_secondary);
}

.input_text_top {
  font-size: 14px;
  color: var(--text_secondary);
}

.input {
  border: 1px solid var(--border_input);
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;
  color: var(--text_secondary);
}

.input::placeholder {
  color: var(--text_placeholder);
}

.admin_textarea {
  width: 100%;
  height: 100%;
  color: var(--text_primary);
  border: 1px solid var(--border_input);
}
