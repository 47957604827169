.wrapper {
  width: 100%;
  height: 100%;
}

.file_btn {
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: var(--text_nav);
  background-color: var(--background_block);
  border: 1.5px dashed var(--border_img);
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file_btn:hover {
  color: var(--text_nav_active);
  background-color: var(--background_block_accent);
}

.file_btn span {
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: var(--text_nav);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: var(--background_block_accent);
}

.selected_file {
  position: absolute;
  right: 12px;
  top: 12px;
  left: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background_block_accent);
  border: 1px solid var(--border_img);
  border-radius: 20px;
  padding: 12px 16px;
}

.selected_file p {
  font-size: 13px;
  font-weight: 500;
  color: var(--text_placeholder);
}

.selected_file button {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text_nav);
  background-color: transparent;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.selected_file button:hover {
  color: var(--text_nav_active);
  background-color: var(--background_page);
}
