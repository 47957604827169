.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}

.banner_wrapper {
  width: 100%;
  padding-top: 30%;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}

.block_wrapper {
  display: flex;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.block_form {
  display: flex;
  padding: 16px;
  min-width: 300px;
  max-width: 300px;
  flex-direction: column;
  gap: 24px;
  background: var(--background_block_accent);
}

.block_info {
  display: flex;
  padding: 16px;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  background: var(--background_block);
}

.title {
  color: var(--text_primary);
  font-size: 24px;
}

.title_accent {
  color: var(--text_primary);
  font-size: 24px;
  font-weight: 700;
}

.input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.input_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--text_secondary);
}

.input_text_top {
  font-size: 14px;
  color: var(--text_secondary);
}

.input {
  border: 1px solid var(--border_input);
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;
  color: var(--text_secondary);
}

.input_item_horizontal_wrapper {
  display: flex;
  gap: 12px;
}

.input_item_horizontal {
  display: flex;
  align-items: center;
  gap: 8px;
}

.content_wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.img_wrapper {
  position: relative;
  min-width: 250px;
  max-width: 250px;
  min-height: 250px;
  max-height: 250px;
  border: 4px solid var(--border_img);
  border-radius: 16px;
  overflow: hidden;
}

.img_welcome {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  color: var(--text_primary);
  font-size: 18px;
  line-height: 135%;
  text-align: justify;
}

.admin_banner_wrapper {
  width: 100% !important;
  padding-top: 30% !important;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}

.upload_file {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.admin_textarea {
  width: 100%;
  height: 100%;
  color: var(--text_primary);
  border: 1px solid var(--border_input);
}

@media screen and (max-width: 1000px) {
  .content_wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  .block_wrapper {
    display: flex;
    flex-direction: column;
  }

  .block_form {
    min-width: 100%;
    max-width: 100%;
  }

  .content_wrapper {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 650px) {
  .content_wrapper {
    display: flex;
    flex-direction: column;
  }
}
