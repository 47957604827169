.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-bottom: 50px;
}

.title {
  font-size: 30px;
  color: var(--text_primary);
}

.title_accent {
  font-size: 30px;
  font-weight: 700;
  color: var(--text_primary);
}

.block_wrapper {
  display: flex;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.block_form {
  display: flex;
  padding: 16px;
  min-width: 400px;
  max-width: 400px;
  flex-direction: column;
  gap: 24px;
  background: var(--background_block_accent);
}

.block_info {
  display: flex;
  width: 100%;
  padding: 16px;
  gap: 50px;
  background: var(--background_block);
}

.block_time {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 50%;
  max-width: 50%;
}

.time_list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--text_primary);
}

.block_contacts {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.block_item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--text_primary);
}

.input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.input_item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--text_secondary);
}

.input_text_top {
  font-size: 14px;
  color: var(--text_secondary);
}

.input {
  border: 1px solid var(--border_input);
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;
  color: var(--text_secondary);
}

.input::placeholder {
  color: var(--text_placeholder);
}

.admin_textarea {
  width: 100%;
  height: 100%;
  color: var(--text_primary);
  border: 1px solid var(--border_input);
}

@media screen and (max-width: 850px) {
  .block_wrapper {
    display: flex;
    flex-direction: column;
  }

  .block_form {
    min-width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 460px) {
  .block_form {
    min-width: 100%;
    max-width: 100%;
  }

  .block_time {
    min-width: 100%;
    max-width: 100%;
  }

  .block_info {
    display: flex;
    flex-direction: column;
  }
}
