.wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.title {
  font-size: 30px;
  color: var(--text_primary);
}

.title_accent {
  font-size: 30px;
  font-weight: 700;
  color: var(--text_primary);
}

.text {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: var(--text_primary);
  gap: 12px;
  line-height: 40px;
  text-align: justify;
}

.link {
  color: var(--text_secondary);
}

.admin_textarea {
  width: 100%;
  min-height: 250px;
  color: var(--text_primary);
  border: 1px solid var(--border_input);
}
