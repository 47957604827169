@import url("https://fonts.googleapis.com/css2?family=Ingrid+Darling&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

.wrapper {
  display: flex;
  gap: 24px;
}

.logo_wrapper {
  font-size: 70px;
  font-family: "Ingrid Darling", cursive;
  font-weight: 400;
  font-style: normal;
  color: var(--text_primary);
}

.navbar_wrapper {
  margin-left: auto;
  margin-top: auto;
}

.header_menu {
}

.header_list {
  display: flex;
  gap: 24px;
}

.nav_wrapper {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  gap: 24px;
}

.nav_item {
  font-size: 20px;
  font-weight: 600;
  color: var(--text_nav);
}

@media (hover: hover) and (pointer: fine) {
  .nav_item:hover {
    opacity: 0.8;
  }
}

.nav_item:active {
  opacity: 0.6;
}

@media screen and (max-width: 850px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .logo_wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  .navbar_wrapper {
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 600px) {
  .header_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.text {
  font-size: 20px;
  font-weight: 600;
  color: var(--text_nav);
}

@media (hover: hover) and (pointer: fine) {
  .text:hover {
    opacity: 0.8;
  }
}

.text:active {
  opacity: 0.6;
}

.text.active {
  position: relative;
  opacity: 1;
  //color: var(--text_nav_active);
  color: red;
}

.text.active::before {
  content: "";
  position: absolute;
  display: flex;
  top: 100%;
  height: 2px;
  width: 100%;
  border-radius: 100px;
}
